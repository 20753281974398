const PriceModule = {
  state: () => ({
    sorting: null,
    filters : { 
      min_price: null,
      max_price: null,
      window_height: null,
      window_width: null,
      door_height: null,
      door_width: null,
      casements_types: [],
      casements_count: [],
      camera_count: [],
      profiles: [],
    }
  }),
  mutations: {
    SET_CATALOGUE_PAGES_SORTING(state, value) {
      state.sorting = value
    },
  },
}

export default PriceModule;
const slider_types =  [ 
  "one_casement",
  "two_casements",
  "three_casements",
  "four_casements",
  "horizontal_one_casements",
  "horizontal_two_casements",
  "horizontal_three_casements",
  ["door", "half_sandwich_door"],
  ["two_casements_with_left_door", "two_casements_with_rigth_door",
  "two_casements_with_left_half_sandwich_door","two_casements_with_rigth_half_sandwich_door"],
  ["one_casement_with_left_door", "one_casement_with_rigth_door",
  "one_casement_with_left_half_sandwich_door", "one_casement_with_rigth_half_sandwich_door"]
];

const values_for_slider_types =  { 
  "one_casement"                  : { window_type: 'one_casement',               door_type: null },
  "two_casements"                 : { window_type: 'two_casements',              door_type: null },
  "three_casements"               : { window_type: 'three_casements',            door_type: null },
  "four_casements"                : { window_type: 'four_casements',             door_type: null },
  "horizontal_one_casements"      : { window_type: 'horizontal_one_casements',   door_type: null },
  "horizontal_two_casements"      : { window_type: 'horizontal_two_casements',   door_type: null },
  "horizontal_three_casements"    : { window_type: 'horizontal_three_casements', door_type: null },
  "door"                          : { window_type:  null,                         door_type: 'rigth'},
  "half_sandwich_door"            : { window_type:  null,                         door_type: 'rigth_with_half_sandwich'},
  "one_casement_with_left_door"   : { window_type: 'one_casement',               door_type: 'left' },
  "one_casement_with_left_half_sandwich_door"   : { window_type: 'one_casement',               door_type: 'left_with_half_sandwich' },
  "one_casement_with_rigth_door"                : { window_type: 'one_casement',               door_type: 'rigth'},
  "one_casement_with_rigth_half_sandwich_door"  : { window_type: 'one_casement',               door_type: 'rigth_with_half_sandwich'},
  "two_casements_with_left_door"                : { window_type: 'two_casements',              door_type: 'left' },
  "two_casements_with_left_half_sandwich_door"  : { window_type: 'two_casements',              door_type: 'left_with_half_sandwich' },
  "two_casements_with_rigth_door"               : { window_type: 'two_casements',              door_type: 'rigth'},
  "two_casements_with_rigth_half_sandwich_door" : { window_type: 'two_casements',              door_type: 'rigth_with_half_sandwich'},
};

const  desctiptions_for_slider_types = { 
  "one_casement"                  : 'одностворчатое окно ПВХ',
  "two_casements"                 : 'двухстворчатое окно ПВХ',
  "three_casements"               : 'трехстворчатое окно ПВХ',
  "four_casements"                : 'четырехстворчатое окно ПВХ',
  "horizontal_one_casements"      : 'одностворчатое с фрамугой окно ПВХ',
  "horizontal_two_casements"      : 'двухстворчатое окно с фрамугой ПВХ',
  "horizontal_three_casements"    : 'трехстворчатое окно с фрамугой ПВХ',
  "door"                          : 'дверь ПВХ',
  "half_sandwich_door"            : 'дверь ПВХ',
  "one_casement_with_left_door"                 : 'балконный блок с одностворчатым окном ПВХ',
  "one_casement_with_left_half_sandwich_door"   : 'балконный блок с одностворчатым окном ПВХ',
  "one_casement_with_rigth_door"                : 'балконный блок с одностворчатым окном ПВХ',
  "one_casement_with_rigth_half_sandwich_door"  : 'балконный блок с одностворчатым окном ПВХ',
  "two_casements_with_left_door"                : 'балконный блок с двухстворчатым окном ПВХ',
  "two_casements_with_left_half_sandwich_door"  : 'балконный блок с двухстворчатым окном ПВХ',
  "two_casements_with_rigth_door"               : 'балконный блок с двухстворчатым окном ПВХ',
  "two_casements_with_rigth_half_sandwich_door" : 'балконный блок с двухстворчатым окном ПВХ',
};

const casements_for_window_types = { 
  one_casement:                 [{width: 87, height: 130, type: "swing_out",   name: 'Центральная'}],

  two_casements:                [{width: 73, height: 130, type: "swing_out",   name: 'Левая'},
                                 {width: 72, height: 130, type: "dead_window", name: 'Правая'}],

  three_casements:              [{width: 60, height: 130, type: "swing_out",   name: 'Левая'},
                                 {width: 60, height: 130, type: "dead_window", name: 'Центральная'},
                                 {width: 60, height: 130, type: "rotary",      name: 'Правая'}],

  four_casements:               [{width: 60, height: 130, type: "dead_window", name: 'Левая'},
                                 {width: 60, height: 130, type: "swing_out",   name: 'Центральная Левая'},
                                 {width: 60, height: 130, type: "rotary",      name: 'Центральная Правая'},
                                 {width: 60, height: 130, type: "dead_window", name: 'Правая'}],

  horizontal_one_casements:     [{width: 70, height: 85,  type: "dead_window", name: 'Фрамуга'},
                                 {width: 70, height: 85,  type: "swing_out",   name: 'Центральная'}],

  horizontal_two_casements:     [{width: 140, height: 60, type: "dead_window", name: 'Фрамуга'},
                                 {width: 70,  height: 140, type: "swing_out",   name: 'Левая'},
                                 {width: 70,  height: 140, type: "rotary",      name: 'Правая'}],

  horizontal_three_casements:   [{width: 160, height: 54,type: "dead_window",  name: 'Фрамуга'},
                                 {width: 53, height: 146, type: "swing_out",    name: 'Левая'},
                                 {width: 54, height: 146, type: "dead_window",  name: 'Центральная'},
                                 {width: 53, height: 146, type: "rotary",       name: 'Правая'}],
};

const casement_for_door_types = {
  left:                     { width: 75, height: 220, type: "swing_out", name: 'Дверь', side: 'left' },
  rigth:                    { width: 75, height: 220, type: "swing_out", name: 'Дверь', side: 'rigth'},
  left_with_half_sandwich:  { width: 75, height: 220, type: "swing_out", name: 'Дверь', side: 'left',  glass_type: 'half_sandwich' },
  rigth_with_half_sandwich: { width: 75, height: 220, type: "swing_out", name: 'Дверь', side: 'rigth', glass_type: 'half_sandwich' }
};


export { slider_types, values_for_slider_types, desctiptions_for_slider_types, casements_for_window_types, casement_for_door_types }

import axios from 'axios';

let user_sign_in_process = false;

const LoggedModule = {
    state: () => ({
        user_signed_in: user_signed_in === 'true',
        error_message: "",
        reset_pwd_success: "",
        current_user_info_present: false,
        admin: false,
        name: undefined,
        balance: undefined,
        calculator_stoped: undefined,
        status: undefined,
        calculator_status: undefined,
        recurrent_pay: undefined,
        payture_cards: undefined,
        limit_msg_timeout_count: 0,
        trial_msg_timeout_count: 0,
        ls: null,
        trial_hours: undefined,
        qr_code: undefined,
        to_open_dialog: false,
     }),
    mutations: {
        SET_QR_CODE(state,qr_code) {
          state.qr_code = qr_code
        },
        SET_RECURRENT_PAY(state,recurrent_pay) {
          state.recurrent_pay = recurrent_pay
        },
        SET_PAYTURE_CARDS_AND_RECCURENT_PAY(state, data) {
          state.recurrent_pay = data.recurrent_pay;
          state.payture_cards = data.cards
        },
        SET_CALCULATOR_STOPED(state, calculator_stoped) {
          state.calculator_stoped = calculator_stoped
        },
        SET_USER_SIGNED_IN(state, user_signed_in) {
          state.user_signed_in = user_signed_in
        },
        SET_CURRENT_USER_INFO(state, info) {
            if (info.trial) {
              state.trial_hours = info.trial;
            }
            state.admin = info.admin;
            state.name = info.name;
            state.balance = info.balance;
            state.calculator_stoped = info.calculator_stoped;
            state.status = info.status;
            state.calculator_status = info.calculator_status;
            state.recurrent_pay = info.recurrent_pay;
            state.payture_cards = info.payture_cards;
            state.current_user_info_present = true;
            state.ls = info.ls;
        },
        CLEAR_CURRENT_USER_INFO(state) {
            state.admin = undefined;
            state.name = undefined;
            state.balance = undefined;
            state.calculator_stoped = undefined;
            state.status = undefined;
            state.calculator_status = undefined;
            state.recurrent_pay = undefined;
            state.payture_cards = undefined;
            state.current_user_info_present = false;
        },
        SET_LOGIN_ERROR_MESSAGE(state, error) {
            state.error_message = error
        },
        SET_RESET_PWD_SUCCESS(state, suc) {
            state.reset_pwd_success = suc
        },
        SET_BALANCE_INFO(state, balance) {
            state.balance = balance
        },
        SET_LIMIT_COUNT_INFO(state, timeout_count) {
            if (timeout_count > 120) {
                state.limit_msg_timeout_count = 1
            } else {
                state.limit_msg_timeout_count = timeout_count
            }
        },
        SET_TRIAL_COUNT_INFO(state, timeout_count) {
            if (timeout_count > 120) {
                state.trial_msg_timeout_count = 1
            } else {
                state.trial_msg_timeout_count = timeout_count
            }
        },
        SET_TO_OPEN_DIALOG(state, value) {
            state.to_open_dialog = value
        }
    },
    actions: {
        paytureRequest({commit,dispatch}, data_for_request) {
            axios.post(data_for_request.url, data_for_request.data).then(res => {
                if (res.data.success) {
                    if (res.data.url) {
                        window.open(res.data.url, '_blank').focus()
                    }
                    if (res.data.file) {
                      const linkSource = `data:application/pdf;base64,${res.data.file.data}`;
                      const downloadLink = document.createElement('a');
                      document.body.appendChild(downloadLink);
                      downloadLink.href = linkSource;
                      downloadLink.target = '_self';
                      downloadLink.download = res.data.file.name
                      downloadLink.click()
                    }
                    if (res.data.msg) {
                        if (data_for_request.log) {
                          data_for_request.log.push({ type: 'success', message: res.data.msg });
                        }
                    }
                    if (data_for_request.commit) {
                        commit(data_for_request.commit, res.data.new_data)
                    }
                } else {
                    if (data_for_request.log) {
                      data_for_request.log.push({ type: 'error', message: res.data.msg });
                    }
                }
          })
        },
        checkUserLogged({state, commit, dispatch}) {
            if (state.user_signed_in) {
                dispatch('getCurrentUserInfo')
            }
            commit('SET_USER_SIGNED_IN', state.user_signed_in)
        },
        loginUser({commit, state, dispatch}, data) {
            user_sign_in_process = true;
            const config = {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              }

            const params = new URLSearchParams();

            params.append('user[email]', data.email);
            params.append('user[password]', data.password);
            params.append('authenticity_token', first_page_token_param);

            axios.post('/auth/login', params, config).then( res => {
              commit('SET_USER_SIGNED_IN', true);
              dispatch('getCurrentUserInfo');
            }).catch(error => {
                console.error("loginUser:", error);
                commit("SET_LOGIN_ERROR_MESSAGE", error.response.data);
            })
        },
        redirectUser({state}) {
            if (state.current_user_info_present) {
                let user_area = state.admin ? 'admin' : 'client';
                let user_subarea = state.admin ? 'companies' : 'company';
                window.location.href= `/${user_area}/${user_subarea}`;
            } else console.error("redirectUser: can't find current user");
        },
        getCurrentUserInfo({commit, dispatch}) {
            axios.get('/api/calculator/current_user_info').then(res => {
                commit('SET_CURRENT_USER_INFO', res.data);
                if (user_sign_in_process) dispatch('redirectUser');
            }).catch(err => {
                console.error("getCurrentUserInfo:", err);
            }).finally(res =>{
                user_sign_in_process = false;
            })
        },
        checkRecurrentPayValues({state, dispatch}, {log}) {
            dispatch('paytureRequest',{ url: '/client/payment/payture/check_recurrent_pay',
                                        commit: 'SET_RECURRENT_PAY',
                                        log })
        },
        getBalance({commit, state}) {
            axios.get('/api/calculator/company_balance').then(res => {
                commit('SET_BALANCE_INFO', res.data.balance);
                if (res.data.balance === 0 && !res.data.company_with_trial) {
                    commit('SET_BALANCE_PAYTURE_MESSAGE', ['Пробный период истек, необходимо','пополнить','баланс для отображения предложений в калькуляторе.']);
                }
                commit('SET_LIMIT_COUNT_INFO', state.limit_msg_timeout_count + 1)
                commit('SET_TRIAL_COUNT_INFO', state.trial_msg_timeout_count + 1)
                if (res.data.limit_msg && state.limit_msg_timeout_count === 1) {
                    setTimeout(() => commit('SET_SUCCESS_PAYTURE_MESSAGE', res.data.limit_msg),  120*60*1000);
                } else if (res.data.limit_msg && state.limit_msg_timeout_count === 120) {
                    commit('SET_SUCCESS_PAYTURE_MESSAGE', res.data.limit_msg);
                }
                if (res.data.trial_msg && state.trial_msg_timeout_count === 1) {
                    setTimeout(() => commit('SET_SUCCESS_PAYTURE_MESSAGE', res.data.trial_msg),  120*60*1000);
                } else if (res.data.trial_msg && state.trial_msg_timeout_count === 120) {
                    commit('SET_SUCCESS_PAYTURE_MESSAGE', res.data.trial_msg);
                }
            }).catch(err => {
                console.error("getBalance:", err);
            })
        },
        logoutUser({commit}) {
            axios.get('/auth/logout').then(res => {
                commit('SET_USER_SIGNED_IN', false);
                commit('CLEAR_CURRENT_USER_INFO');
            })
        },
        resetPwd({commit}, email) {
            let params = {
                email: email
            }
            commit('SET_LOGIN_ERROR_MESSAGE', "")
            axios.post('/api/calculator/reset_password', params).then(res => {
                if (res.data.message) commit("SET_RESET_PWD_SUCCESS", res.data.message)
            }).catch(err => {
                console.error("reset pwd error", err.response.data)
                if (err.response.data.message) commit('SET_LOGIN_ERROR_MESSAGE', err.response.data.message);
            })

        }
    },
}

export default LoggedModule;

import GTService from '../../../services/GlassTypeService.js';

const GlassTypeModule = {
  state: () => ({
    // для full_search-запроса
    selected_glass_types: [],
  }),
  mutations: {
    SET_SELECTED_GLASS_TYPES(state, ids) {
      state.selected_glass_types = ids
    }
  },
}

export default GlassTypeModule;
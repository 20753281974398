const FurnitureModelModule = {
  state: () => ({
    selected_furnitures: [],
  }),
  mutations: {
    SET_SELECTED_FURNITURES(state, value) {
      state.selected_furnitures = value
    },
  },
  actions: {
    setSelectedFurnitures({ dispatch, commit }, value) {
      commit('SET_SELECTED_FURNITURES', value)
      dispatch('getTimeoutFiltersOffers')
    },
  }
}


export default FurnitureModelModule;
import axios from 'axios'
import { createStore } from 'vuex'

import router from '../router/index'
import { railsData } from "../init_data/rails_data.js";

import { isMobile, isIOS  } from 'mobile-device-detect';

/**
 * FILTERS LEFT
 */

import ProfileModule from './modules/filters/ProfileModule'


import ProfileModelModule from './modules/filters/ProfileModelModule'
import PriceModule from './modules/filters/PriceModule'
import CataloguePageModule from './modules/filters/CataloguePageModule'
import LandingModule from './modules/landing/LandingModule'
import GlassTypeModule from './modules/filters/GlassTypeModule'
import AdditionalServicesModule from './modules/filters/AdditionalServicesModule'
import FurnitureModelModule from './modules/filters/FurnitureModelModule'
import CompanyModule from './modules/filters/CompanyModule'
import CitiesModule from './modules/filters/CitiesModule'

/**
 * FILTERS TOP
 */
import CurrentWindowModule from './modules/top_filters/CurrentWindowModule'
import SortFilterModule from './modules/top_filters/SortFilterModule'

/**
 * OTHER
 */
import InitialDataModule from './modules/initial_data/InitialDataModule'
import OffersCartModule from './modules/cart/OffersCartModule'
import PageModule from './modules/filters/PageModule'
import StatisticModule from './modules/statistic/StatisticModule'
import LoggedModule from './modules/user/LoggedModule'
import CompaniesListModule from './modules/companies/CompaniesListModule'
import MeasureRequestCompaniesModule from './modules/MeasureRequestCompaniesModule'

/**
 * FORMS
 */

import OrderFormModule from './modules/forms/OrderFormModule'

const state = {
    page_load: false,
    help_button_all_cities_selected_dialog: false,
		first_page_load: (["/","/moskva"].includes(window.location.pathname)),
		aggregator_page_load: false,
	  isMobile: isMobile,
    isIOS: isIOS,
    casement_is_load: false,
    offers: [],
    offers_count: 0,
    min_base_price: undefined,
    max_base_price: undefined,
    full_search_sent: false,
    full_search_loaded: false,
    full_search_click: false,
    error_message: '',
    full_search_error: false,
    preview_offers: {},
    limit: undefined,
		timeout: false,
		show_without_window: false,
		show_without_filters: false,
    show_without_filters_hint: false,
    success_payture_message: undefined,
    balance_payture_message: undefined,
    payture_showing: false,
    prerender: navigator.userAgent.toLowerCase().includes('prerender'),
    without_advert: (railsData.without_advert),
    active_slider_tab: undefined,
    area_input_dialog_show: false,
    without_tops: false
}

const mutations = {
    SET_PAGE_LOAD(state) {
      state.page_load = true
    },
    SET_HELP_BUTTON_ALL_SELECTED_CITIES_DIALOG(state, value) {
      state.help_button_all_cities_selected_dialog = value
    },
    SET_SLIDER_TAB(state,curr_tab) {
      state.active_slider_tab = curr_tab
    },
    SET_PAYTURE_SHOWING(state,value) {
      state.payture_showing = value
    },
    SET_AREA_INPUT_DIALOG_SHOW(state, value) {
      state.area_input_dialog_show = value
    },
    SET_SUCCESS_PAYTURE_MESSAGE(state,success_payture_msg) {
      state.success_payture_message = success_payture_msg
    },
    SET_BALANCE_PAYTURE_MESSAGE(state,balance_payture_msg) {
      state.balance_payture_message = balance_payture_msg
    },
    SET_AGGREGATOR_LOAD(state) {
        state.aggregator_page_load = true
    },
    SET_LIMIT(state,value) {
      state.limit = value
    },
    SET_TIMEOUT(state,value) {
        state.timeout = value
    },
    SET_CASEMENT_LOAD(state) {
        state.casement_is_load = true
    },
    SET_RESPONSE_PARAMS(state, resp) {
        state.offers_count    = resp.add_data.count;
        state.min_base_price = resp.add_data.min;
        state.max_base_price = resp.add_data.max;
        state.offers = resp.offers
				state.offers.forEach ((offer,index) => {
					if (index < 3) {
						offer.isActive = true
					} else {
						offer.isActive = state.prerender
					}
				})
    },
    SET_ERROR_MESSAGE(state, message) {
        state.error_message = message
    },
    SET_FULLSEARCH_SENT(state, fs_sent) {
        state.full_search_sent = fs_sent;
    },
    SET_FULLSEARCH_LOADED(state) {
      console.log('SET_FULLSEARCH_LOADED');
      state.full_search_loaded = true
    },
    SET_OFFERS(state, offers) {
        state.offers = offers;
    },
    SET_FULLSEARCH_CLICK(state, fs_click) {
        state.full_search_click = fs_click;
    },
    SET_FULLSEARCH_ERROR(state, error) {
        state.full_search_error = error;
    },
    SET_OFFERS_COUNT(state, offers_count) {
        state.offers_count = offers_count;
    },
    SET_PREVIEW_OFFERS(state, preview_offers) {
        state.preview_offers = preview_offers;
    },
    SET_WITHOUT_WINDOW(state, value) {
        state.show_without_window = value;
    },
    SET_WITHOUT_FILTERS(state, value) {
        state.show_without_filters = value;
    },
    SET_WITHOUT_FILTERS_HINT(state, value) {
        state.show_without_filters_hint = value;
    },
    WITHOUT_TOPS(state) {
      state.without_tops = true
    },
}

const actions = {
    setPaytureSuccessMessage ({commit,dispatch}, msg) {
				commit('SET_SUCCESS_PAYTURE_MESSAGE',msg);
        setTimeout(() => {
          commit("SET_SUCCESS_PAYTURE_MESSAGE",'')
        }, 60000)
    },
		getTimeoutFiltersOffers({commit,dispatch,state}, deley = 1000) {
			if (typeof state.timeout === 'number') {
				clearTimeout(state.timeout);
				commit('SET_TIMEOUT',false)
			}
			let id = setTimeout(() => {
				dispatch("getOffers")
			},deley);
			commit('SET_TIMEOUT',id)
		},
		goToAgregator( { commit, dispatch } ) {
			/**
			/ before moving to the second page
			/	> resolve full_search (didn't have time finally block)
			/ > call full_search
			**/
			commit('SET_FULLSEARCH_SENT', false);
			commit('SET_LIMIT', undefined);
			commit('SET_AGGREGATOR_LOAD');
			dispatch("getOffers")
			router.push('calc');
		},
		submitForm({}, submit_params) {
      let form = document.createElement("form");
      form.method = "POST";
      form.action = submit_params.url;

      let params = submit_params.params;

      params.authenticity_token = first_page_token_param;

      let element = [];
      for (let key in params) {
        element[key] = document.createElement("input");
        element[key].setAttribute("type", "hidden");
        element[key].value = params[key];
        element[key].name = key;
        form.appendChild(element[key]);
      }
      document.body.appendChild(form);
      if (submit_params.target_blank) {
        form.setAttribute("target", "_blank");
        form.setAttribute("rel", "noopener");
      }
      form.submit();
		},
    getOffers({ commit, getters, state, dispatch }, page) {
				// if not pagination set 1 page
				if (page) {
					commit('SET_PAGE', page);
				} else {
					commit('SET_PAGE', 1);
				}
        commit('SET_FULLSEARCH_ERROR', false)
        commit('SET_OFFERS_COUNT', 0)
        if (!state.full_search_sent) {
            commit('SET_FULLSEARCH_SENT', true);
            axios.post('/api/calculator/full_search.json', getters.params).then(response => {
                commit('SET_RESPONSE_PARAMS', response.data);
            }).catch(function (error) {
                let parsed = JSON.parse(error.request.response)
                console.error("FULLSEARCH error", error, parsed);
                commit('SET_FULLSEARCH_ERROR', parsed.error)
            }).finally(function (res) {
								commit('SET_FULLSEARCH_LOADED');
                commit('SET_FULLSEARCH_SENT', false);
								commit('SET_TIMEOUT',false)
                if (state.full_search_click) {
                    commit('SET_FULLSEARCH_CLICK', false);
                    dispatch('getOffers')
                }
            })
        } else {
            commit('SET_FULLSEARCH_CLICK', true);
        }
    },
}

const getters = {
		offers_patterns: state => {
      const offers_count   = state.offers_count || 21;
      const min_base_price = state.min_base_price || 14391;
      const max_base_price = state.max_base_price || 48492;
      return {
        '[[company_count]]': offers_count,
        '[[offers_count]]':  offers_count * 16,
        '[[min_price]]':     min_base_price,
        '[[max_price]]':     max_base_price,
        '[[m_price]]':       min_base_price / 2,
      }
		},
		subdomain: state => {
			let selected_cities = state.citiesM.selected_cities;
      if (selected_cities.length === 1) return `${selected_cities[0].slug}.`
      else return ''
		},
		city_for_statistic: state => {
			let selected_cities = state.citiesM.selected_cities;
			let user_geo = state.citiesM.user_geo;
			if (selected_cities.length === 1) {
				return selected_cities[0].id
			}
			if (user_geo) {
				return user_geo.id
			}
		},
		full_construction: state => {
			let codes = state.additionalServicesM.selectedAS;
			let fl_codes = ["as_sill_inside_white", "as_sill_outside", "as_delivery_in_city", "as_panel", "as_jambs", "as_dismantle"];
			return fl_codes.every((item) => codes.includes(item))
		},
    current_calculator_params: state => {
        let params = {
						// limit: state.limit,
            window_casements: state.currentWindowM.window_casements,
            door_casement: state.currentWindowM.door_casement,
						companies: state.companyM.selected_companies,
            active_sorting: state.sortFilterM.active_sorting,
            additional_services: state.additionalServicesM.selectedAS,
            furniture: state.furnitureModelM.selected_furnitures,
            profiles: state.profileModelM.selected_profiles,
            price_type: state.priceM.price_type,
            quality: state.priceM.prices,
            quantity: state.currentWindowM.quantity,
            sort_price: state.sortFilterM.sort_price,
            sort_rating: state.sortFilterM.sort_rating,
            window_color_inside: state.currentWindowM.window_color_inside,
            window_color_outside: state.currentWindowM.window_color_outside,
            window_color_solid: state.currentWindowM.window_color_solid,
            as_mosquitoscreen: state.currentWindowM.as_mosquitoscreen,
            as_petscreen: state.currentWindowM.as_petscreen,
            as_dustscreen: state.currentWindowM.as_dustscreen,
            delivery_distance: state.additionalServicesM.delivery_distance,
            sill_inside_depth: state.additionalServicesM.sill_inside_depth,
            sill_inside_color: state.additionalServicesM.sill_inside_color,
            sill_outside_depth: state.additionalServicesM.sill_outside_depth,
            jambs_depth: state.additionalServicesM.jambs_depth,
						show_without_window: state.show_without_window,
						show_without_filters: state.show_without_filters,
            show_without_filters_hint: state.show_without_filters_hint,
            ...state.profileM.profile_params.params
        }

        return params;
    },
    params: (state)=> {
        var params = {
						city_ids: state.citiesM.selected_cities.map(item => item.id),
            window_casements: state.currentWindowM.window_casements,
            door_casement: state.currentWindowM.door_casement,
            companies: state.companyM.selected_companies,
            active_sorting: state.sortFilterM.active_sorting,
            without_tops: state.without_tops,
            additional_services: state.additionalServicesM.selectedAS,
            furniture: state.furnitureModelM.selected_furnitures,
            profiles: state.profileModelM.selected_profiles,
            price_type: state.priceM.price_type,
            quality: state.priceM.prices,
            quantity: state.currentWindowM.quantity,
            sort_price: state.sortFilterM.sort_price,
            sort_rating: state.sortFilterM.sort_rating,
            window_color_inside: state.currentWindowM.window_color_inside,
            window_color_outside: state.currentWindowM.window_color_outside,
            window_color_solid: state.currentWindowM.window_color_solid,
            as_mosquitoscreen: state.currentWindowM.as_mosquitoscreen,
            as_petscreen: state.currentWindowM.as_petscreen,
            as_dustscreen: state.currentWindowM.as_dustscreen,
            delivery_distance: state.additionalServicesM.delivery_distance,
            sill_inside_depth: state.additionalServicesM.sill_inside_depth,
            sill_inside_color: state.additionalServicesM.sill_inside_color,
            sill_outside_depth: state.additionalServicesM.sill_outside_depth,
            jambs_depth: state.additionalServicesM.jambs_depth,
						limit:  state.limit,
            page: state.pageM.page,
            per_page: state.pageM.per_page,
            ...state.profileM.profile_params.params
        }

        return params;
    },
}

const store = createStore({
    state,
    getters,
    actions,
    mutations,
    modules: {
        profileM: ProfileModule,
        profileModelM: ProfileModelModule,
        LandingM: LandingModule,
        priceM: PriceModule,
        CataloguePageM: CataloguePageModule,
        currentWindowM: CurrentWindowModule,
        initialDataM: InitialDataModule,
        sortFilterM: SortFilterModule,
        glassTypeM: GlassTypeModule,
        additionalServicesM: AdditionalServicesModule,
        furnitureModelM: FurnitureModelModule,
        companyM: CompanyModule,
        offersCartM: OffersCartModule,
        citiesM: CitiesModule,
        pageM: PageModule,
        statisticM: StatisticModule,
        loggedM: LoggedModule,
        OrderFormM: OrderFormModule,
        companiesListM: CompaniesListModule,
        measureRequestCompaniesM: MeasureRequestCompaniesModule
    }
});

store.watch((state) => store.getters.avalibl_parts_for_grid, (val) => {
  if (val.length === 0) {
    store.dispatch("validateGlassAllDeadCasements");
  }
});


export default store;

import axios from 'axios'

const CompaniesListModule = {
  state: () => ({
    companies: [],
    companies_count: 0,
    companies_loaded: false,
    pagination_companies: {
      page: 1,
      per_page: 50
    },
    reviews: [],
    reviews_count: 0,
    reviews_loaded: false,
    page_reviews: 1,
  }),
  mutations: {
    SET_COMPANIES_LIST(state, companies) {
      state.companies = companies
    },
    SET_REVIEWS(state, reviews) {
      state.reviews = reviews
      state.reviews.forEach(rev => rev.isActive = true)
    },
    SET_COMPANIES_COUNT(state, companies_count) {
      state.companies_count = companies_count
    },
    SET_REVIEWS_COUNT(state, reviews_count) {
      state.reviews_count = reviews_count
    },
    SET_REVIEWS_LIST_PAGE(state, page) {
      state.page_reviews = page
    },
    SET_COMPANIES_LIST_PAGINATION(state, pagination) {
      state.pagination_companies = pagination
    },
    SET_COMPANIES_LOADED(state) {
      state.companies_loaded = true
    },
    SET_REVIEWS_LOADED(state) {
      state.reviews_loaded = true
    }
  },
  actions: {
    getCompaniesList({ commit, state }, filters) {
			axios.get('/kompanii.json', { params: filters }).then(response => {
        commit('SET_COMPANIES_LOADED');
        let new_companies = JSON.parse(response.data.results)
        let companies_count = response.data.companies_count
        if (new_companies.length == 0 && companies_count !== 0) {
          let per_page = state.pagination_companies.per_page
          commit('SET_COMPANIES_LIST_PAGINATION',{page:1, per_page:per_page})
        } else {
          commit('SET_COMPANIES_LIST', new_companies)
          commit('SET_COMPANIES_COUNT', companies_count)
        }
      })
    },
    loadReviews({ commit }, data_for_request) {
      axios.get(data_for_request.url, {
        params: data_for_request.params
      }).then(res => {
        commit('SET_REVIEWS_LOADED');
        let reviews = res.data.reviews;
        let reviews_count = res.data.count;
        if (reviews.length == 0 && reviews_count !== 0) {
          commit('SET_REVIEWS_LIST_PAGE', 1);
        } else {
          commit('SET_REVIEWS', reviews)
          commit('SET_REVIEWS_COUNT', reviews_count)
        }
      })
    }
  }
}

export default CompaniesListModule;

import axios from 'axios'
import store from "../../../store";
import router_landing from '../../../router_landing'
import { railsData } from "../../../init_data/rails_data.js";

const LandingModule = {
  state: () => ({
    page_params: {},
    page_name: '',
    common_params: {},
    city_name: '',
    text: '',
    header_h1: '',
    header_h2: '',
    reviews: [],
    coords: [],
    map_position: [],
    last_calcs: [],
    header_menu: [],
    saved_offers: [],
  }),
  mutations: {
    SET_PAGE_PARAMS_AND_DATA(state, params) {
      state.page_params  = params.page_params;
      state.page_name = params.page_name;
      state.city_name    = params.city_name;
      state.last_calcs   = params.last_calcs;
      if (params.reviews) {
        state.reviews  = JSON.parse(params.reviews);
        state.reviews.forEach ((rev) => {
          rev.isActive = true
        })
      }
      state.coords        = params.coords;
      state.saved_offers  = params.saved_offers;
      state.map_position  = params.map_position;
    },
    SET_COMMON_PARAMS(state, params) {
      state.common_params = params
    },
  },
  actions: {
    select_one_city({commit, dispatch}) {
			commit("OPEN_SELECT_CITY_DIALOG");
      const unwatch_selected_cities = store.watch((state) => state.citiesM.selected_cities, (selected) => {
        if (selected.length > 1) {
          commit("SET_SELECTED_CITIES", [selected.pop()]);
          return
        }
        commit("CLOZE_SELECT_CITY_DIALOG");
        let city_slug;
        if (selected.length === 1) {
          city_slug = selected[0].slug
        }
			  router_landing.push({ name: 'Home', params: { city_slug }});
      });
      const unwatch_city_dialog = store.watch((state) => state.citiesM.city_select_dialog, () => {
        unwatch_selected_cities()
        unwatch_city_dialog()
      });
    },
    setInitData({ commit }) {
      commit("SET_COMMON_PARAMS", railsData.common_params);
    },
    getPageParams({ commit }, page_url) {
      if (page_url === railsData.first_request_url) {
        commit("SET_PAGE_PARAMS_AND_DATA", railsData.page_data);
        commit("SET_OFFERS_COUNT", railsData.page_data.company_count);
        if (railsData.city) commit("SET_SELECTED_CITIES", [railsData.city])
        else commit("SET_SELECTED_CITIES", [])
      } else {
        if (page_url === '/') page_url = '/home'
        axios.get(`${page_url}.json`).then(res => {
          commit("SET_PAGE_PARAMS_AND_DATA", res.data)
          commit("SET_OFFERS_COUNT", res.data.company_count);
        })
      }
    },
  }
}

export default LandingModule;

const ProfileModelModule = {
  state: () => ({
    selected_profiles: [],
  }),
  mutations: {
    SET_SELECTED_PROFILES(state, profiles) {
      state.selected_profiles = profiles
    },
  },
  actions: {
    setSelectedProfiles({ dispatch, commit }, profiles) {
      commit('SET_SELECTED_PROFILES', profiles)
      dispatch('getTimeoutFiltersOffers')
    },
  }
}


export default ProfileModelModule;
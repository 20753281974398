import {createRouter, createWebHistory} from 'vue-router'
import store from '../store';

const Home = () => import('../views/Home.vue');
const SetWindow = () => import('../views/SetWindow.vue');
const RepairWindow = () => import('../views/RepairWindow.vue');

const ShoppingCart = () => import('../views/cart/ShoppingCart.vue');
const InCartList = () => import('../views/cart/InCartList.vue');
const ShippedCartList = () => import('../views/cart/ShippedCartList.vue');
const QuickOrder = () => import('../views/cart/QuickOrder.vue');
const MeasureRequestContent = () => import('../views/cart/MeasureRequestContent.vue');
const MinPriceMeasure = () => import('../views/MinPriceMeasure.vue');

const SiteInfo = () => import('../views/SiteInfo.vue');

const CataloguePage = () => import('../views/CataloguePage.vue');
const Agregator = () => import('../views/Agregator.vue');
const StaticAgregator = () => import('../views/StaticAgregator.vue');
const MinPriceOffer = () => import('../views/MinPriceOffer.vue');
const CompaniesList = () => import("../views/CompaniesList.vue");
const ReviewsList = () => import("../views/ReviewsList.vue");


const SetAdminParams = () => import("../views/SetAdminParams.vue");
const SetAdminFilters = () => import("../views/SetAdminFilters.vue");
const SetAdminWindow = () => import("../views/SetAdminWindow.vue");
const PaymentResult = () => import("../views/PaymentResult.vue");
const CompanyPage = () => import("../views/CompanyPage.vue");

const routes = [
  {
    path: '/',
    component: Home,
    name: 'Home',
    meta: {
      title: `Калькулятор Окон`,
    },
    children: [
      {
        path: '',
        component: SetWindow,
        name: 'SetWindow',
      },
      {
        path: 'repair',
				name: 'RepairWindow',
        component: RepairWindow
      }
    ]
  },
  {
    path: '/moskva',
    component: Home,
    name: 'HomeMoskva',
    meta: {
      title: `Калькулятор Окон`,
    },
    children: [
      {
        path: '',
        component: SetWindow,
        name: 'SetWindowMoskva',
      }
    ]
  },
  {
    path:'/payment_result',
    component: PaymentResult,
    name: "payment_result",
    meta: {
      title: `Результат оплаты`,
    }
  },
  {
    path:'/min_price_measure/:session_id/:id',
    component: MinPriceMeasure,
    name: "min_price_measure",
  },
  {
    path: '/cart',
    component: ShoppingCart,
    name: 'ShoppingCart',
    meta: {
      title: `Корзина`,
      noShowCartIcon: true
    },
    children: [
      {
        path: '',
        component: InCartList,
        name: 'InCartList',
      },
      {
        path: 'shipped',
        component: ShippedCartList,
				name: 'ShippedCartList',
      },
      {
        path: 'quick',
        component: QuickOrder,
				name: 'QuickOrder',
      }
    ]
  },
  {
    path: '/cart/measure_request_content/:guid',
    component: MeasureRequestContent,
    name: 'MeasureRequestContent',
    meta: {
      title: `Приложения к заявке`,
    }
  },
  {
    path: '/company/:company_slug',
    component: Agregator,
    name: "CompanyAgregator"
  },
  {
    path: '/otzyvy',
    component: ReviewsList,
    name: "ReviewsList",
  },
  {
    path: '/kompanii',
    component: CompaniesList,
    name: "CompaniesList",
  },
  {
    path: '/kompanii/:company_slug',
    component: CompanyPage,
    meta: { withoutScroll: true },
    name: "CompanyPage",
  },
  {
    path: '/calc',
    component: Agregator,
    name: 'Agregator',
  },
  {
		path: '/calc/admin/set_filters',
    component: SetAdminParams,
    name: 'SetAdminParams',
    children: [
      {
        path: 'filters/:id',
        component: SetAdminFilters,
        name: 'SetAdminFilters',
      },
      {
        path: 'window/:id',
        component: SetAdminWindow,
        name: 'SetAdminWindow',
      }
    ]
  },
  {
    path: '/karta_saita',
    alias: ['/regions_prices','/reklama','/regiony','/o-kompanii','/oferta', '/pravila','/kontakty'],
    component: SiteInfo,
    name: "SiteInfo",
  },
  {
	path:'/moskva/catalogue_pages/:page_slug',
    component: CataloguePage,
    name: "catalogue_page_moskva",
    meta: {
      title: `Catalogue Page`,
    }
  },
  {
	path:'/catalogue_pages/:page_slug',
    component: CataloguePage,
    name: "catalogue_page_others",
    meta: {
      title: `Catalogue Page`,
    }
  },
  {
	path:'/:static_proposal',
    component: StaticAgregator,
    name: "static_agregator",
  },
  {
	path:'/moskva/:static_proposal/min_price',
    component: MinPriceOffer,
    name: "min_price_offer_moskva",
  },
  {
	path:'/:static_proposal/min_price',
    component: MinPriceOffer,
    name: "min_price_offer_others",
  },
  {
	path:'/:city_slug/:static_proposal',
    component: StaticAgregator,
    name: "static_agregator_with_city",
  },
]

const router = createRouter({
  history: createWebHistory(), // <-- this is a new property and it is mandatory!
  routes,
  scrollBehavior(to) {
    if (to.meta.withoutScroll) return false
    return { top: 0 }
  },
})

router.afterEach((to) => {
  store.commit("SET_PAGE_LOAD")
});

// router.afterEach((to) => {
//     nextTick(() => {
//         document.title = to.meta.title || DEFAULT_TITLE;
//     });
// });

export default router

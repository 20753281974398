const PriceModule = {
  state: () => ({
    prices: [0, 3000000],
    price_type: "per_window",
  }),
  mutations: {
    SET_PRICES(state, prices) {
      state.prices = prices
    },
    SET_PRICES_TYPE(state, price_type) {
      state.price_type = price_type
    },
  },
  actions: {
    setPrices({ commit, dispatch }, prices) {
      commit('SET_PRICES', prices)
      dispatch('getOffers')
    },
    setPriceType({ commit, dispatch }, price_type) {
      commit('SET_PRICES_TYPE', price_type)
      dispatch('getOffers')
    }
  }
}

export default PriceModule;
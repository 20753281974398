import { railsData } from "../../../init_data/rails_data.js";

const InitialDataModule = {
  state: () => ({
    protocol: window.location.protocol,
    hostname: window.location.hostname,
    origin:   window.location.origin,
  }),
  actions: {
    getInitialParams({ rootState, commit, dispatch }) {
			let filters;

      if (railsData.init_filters) filters = JSON.parse(railsData.init_filters)
      if (railsData.filters) filters = JSON.parse(railsData.filters) // Static page
      if (filters) dispatch('setFiltersFromGon',filters);

      if (railsData.current_company) {
        // SHOW SELECT COMPANY OFFERS
        commit('SET_SELECTED_COMPANIES', [parseInt(railsData.current_company)]);
      } else if (railsData.bulk_discounts_city) {
        commit('SET_AREA_INPUT_DIALOG_SHOW', true);
        dispatch('setSelectedCities', JSON.parse(railsData.bulk_discounts_city));
      } else {
			  // Static page with city  or  set from cookie
        if (railsData.city){
			    dispatch('setSelectedCities', [railsData.city]);
        } else {
          dispatch('getCityFromCookie');
        }
      }
      if (railsData.offers_data) commit('SET_RESPONSE_PARAMS', railsData.offers_data)
			// search user geo and set city if first page and cookie empty and current_company empty
			dispatch("searchCity");
			//	 if aggregator loader  =>  do not show offers on first page
			//	 if first page  => limit 3 offer on first page, take city GEO, and Init slider ('two casement')
			if (rootState.first_page_load) {
				commit("SET_LIMIT", 3);
			} else {
				commit("SET_AGGREGATOR_LOAD");
			}
			if (rootState.offers.length === 0) dispatch("getOffers")
		},
    clearFilters({ dispatch }) {
      const filters = JSON.parse(railsData.init_filters);
      dispatch('setFiltersFromGon',filters);
      dispatch("getOffers")
    },
    setFiltersFromGon({ commit, dispatch },filters) {
      if (filters.selected_cities) {
			  commit('SET_SELECTED_CITIES', filters.selected_cities);
      }
			commit('SET_SELECTED_COMPANIES', filters.companies);
			//*************
			//LIMIT OFFER AND SHOW?
			commit('SET_LIMIT', filters.limit);
			commit('SET_WITHOUT_WINDOW', filters.show_without_window);
			commit('SET_WITHOUT_FILTERS', filters.show_without_filters);
			commit('SET_WITHOUT_FILTERS_HINT', filters.show_without_filters_hint);
			//***********************
			//WINDOW COSTRUCTION PARAMS
      // commit('SET_DOOR', filters.door);
      // dispatch('setCasements', filters.casements);

      commit('SET_DOOR_CASEMENT', filters.door_casement);
      commit('SET_WINDOW_CASEMENTS', filters.window_casements);
			// *******************
			// ADITIONAL SERVICES
      commit("SET_SELECTED_ADDITIONAL_SERVICES",filters.additional_services);
			if (filters.delivery_distance) {
				commit("SET_DELIVERY_DISTANCE", filters.delivery_distance)
			}
      commit("SET_SILL_INSIDE_DEPTH", filters.sill_inside_depth)
      commit("SET_SILL_INSIDE_COLOR", filters.sill_inside_color)
      commit("SET_SILL_OUTSIDE_DEPTH", filters.sill_outside_depth)
      commit("SET_JAMBS_DEPTH", filters.jambs_depth)

      // GRIDS WINDOW
      commit('SET_GRID',['as_mosquitoscreen', filters.as_mosquitoscreen]);
      commit('SET_GRID',['as_petscreen', filters.as_petscreen]);
      commit('SET_GRID',['as_dustscreen', filters.as_dustscreen]);

			//********
			//QUNTITY
      commit("SET_QUANTITY", filters.quantity);
			// *******
			//$PRICE
			commit('SET_PRICES', filters.quality)
      commit('SET_PRICES_TYPE', filters.price_type)
			//********
			//SORTING
			commit('SET_ACTIVE_SORTING', filters.active_sorting)
			commit('SET_SORT_PRICE', filters.sort_price);
			commit('SET_SORT_RATING', filters.sort_rating);
			//************
			//SORT PANEL
			commit('SET_SELECTED_FURNITURES', filters.furniture);
			commit('SET_SELECTED_PROFILES', filters.profiles);

			//!!!!!!!!!!!!!!!!!!!!!!!!!!
      commit('SET_PROFILE_PARAMS', filters);
			//**************
			//WINDOW_COLOR
      commit("SET_WINDOW_COLOR_INSIDE", filters.window_color_inside);
      commit("SET_WINDOW_COLOR_OUTSIDE", filters.window_color_outside);
      commit("SET_WINDOW_COLOR_SOLID", filters.window_color_solid);
    },
  }
}


export default InitialDataModule;

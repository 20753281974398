const AdditionalServicesModule = {
  state: () => ({
    selectedAS: [], // selected additional services
    delivery_distance: '',
    sill_inside_depth: undefined,
    sill_inside_color: undefined,
    sill_outside_depth: 130,
    jambs_depth: 200,
  }),
  mutations: {
    SET_SELECTED_ADDITIONAL_SERVICES(state, value) {
      state.selectedAS = value
    },
    SET_SILL_INSIDE_DEPTH(state, value) {
      state.sill_inside_depth = value
    },
    SET_SILL_OUTSIDE_DEPTH(state, value) {
      state.sill_outside_depth = value
    },
    SET_JAMBS_DEPTH(state, value) {
      state.jambs_depth = value
    },
    SET_SILL_INSIDE_COLOR(state, value) {
      state.sill_inside_color = value
    },
    SET_DELIVERY_DISTANCE(state, value) {
      state.delivery_distance = value
    },
    TOGGLE_ADDITIONAL_SERVICES(state, [code, toAdditionalServices]) {
      // toAdditionalServices - флаг для добавления code в AS
      let new_as_codes = [...state.selectedAS];
      if (toAdditionalServices) new_as_codes.push(code)
      else new_as_codes = new_as_codes.filter(item => item != code);
      state.selectedAS = new_as_codes
    },
  },
}

export default AdditionalServicesModule;

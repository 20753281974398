import axios from 'axios';

import router from '../../router/index';

const MeasureRequestCompaniesModule = {
    state: () => ({
      companies_list: [],
      all_cities_selected_dialog: false,
      cities_for_repair_confirmation: false
    }),
    mutations: {
      SET_MEASURE_REQUEST_COMPANIES_LIST(state, companies) {
        state.companies_list = companies
      },
      SET_ALL_SELECTED_CITIES_DIALOG(state, dialog_active) {
        state.all_cities_selected_dialog = dialog_active;
      },
      SET_CITIES_FOR_REPAIR_CONFIRMATION(state, confirmation) {
        state.cities_for_repair_confirmation = confirmation
      }
    },
    actions: {
      getRepairCompanies({getters, rootState, commit}) {
        let params = { 
          cities_ids: getters.params.city_ids,
          activities: rootState.OrderFormM.activities
        }
        commit('FORM_SET_REPAIR_STATUS','load');
        axios.post('/api/calculator/get_repair_companies.json', params).then(response => {
          commit('FORM_SET_ACTIVITIES_TOUCHED',false);
          let companies_list = response.data.companies;
          commit('SET_MEASURE_REQUEST_COMPANIES_LIST',companies_list)
          let companies_list_ids = companies_list.map(company => company.id)
          let selected_companies = rootState.OrderFormM.selected_companies.filter(company_id => companies_list_ids.includes(company_id))
          commit('FORM_SET_SELECTED_COMPANIES',selected_companies)
          commit('FORM_SET_REPAIR_STATUS','send')
        }).catch(function (error) {
          let parsed = JSON.parse(error.request.response);
          console.error("api error", error, parsed);
        })
      },
    }
}
	
export default MeasureRequestCompaniesModule;

import {createRouter, createWebHistory} from 'vue-router'

const Home = () => import(/* webpackPrefetch: true */ '../views/landing/Home.vue');
const WithStatic = () => import(/* webpackPrefetch: true */ '../views/landing/WithStatic.vue');

const routes = [
  {
    path:'/:city_slug?',
    component: Home,
    name: "Home",
  },
  {
    path:'/:city_slug?/:sp_slug',
    component: WithStatic,
    name: "WithStatic",
  },
]

const router = createRouter({
  history: createWebHistory(), // <-- this is a new property and it is mandatory!
  routes,
})

export default router

import axios from 'axios';

const OffersCartModule = {
    state: () => ({
        in_cart: [],
        ordered: [],
        total_count: 0,
        in_cart_count: 0,
        ordered_count: 0,
        cart_sent: [],

        order_complite: false,
        selected_in_cart_offers: [], // ShoppingCart
        selected_ordered_offers: [],
        in_cart_offers: [], // ShoppingCart
        ordered_offers: [],
        measure_request_content: {},

    }),
    mutations: {
        ORDER_COMPLITE(state) {
          state.order_complite = true
        },
        OFFERS_ORDER_FORM_REFRESH(state) {
          state.order_complite = false
        },
        ADD_TO_CART(state, offer) {
          state.in_cart.push(offer)
        },
        REMOVE_FROM_CART(state, offer) {
			    state.in_cart = state.in_cart.filter(item => item !== offer);
        },
        SET_ITEM_CART_SENT(state, offer_uniq) {
          state.cart_sent.push(offer_uniq)
        },
        REMOVE_ITEM_CART_SENT(state, offer_uniq) {
			    state.cart_sent = state.cart_sent.filter(item => item !== offer_uniq);
        },
        FETCH_BASKET_STATUS(state, data) {
          state.total_count = data.total
          state.in_cart_count = data.incart
          state.ordered_count = data.ordered
        },
				SET_IN_CART_OFFERS(state, offers) {
					state.in_cart_offers = offers
				},
        REMOVE_FROM_ORDERED_OFFERS(state, uniq) {
          let new_in_cart_offers = [];
          state.ordered_offers.forEach((company) => {
            let offers = company.offers.filter(offer => offer.uniq !== uniq);
            if (offers.length) {
              company.offers = offers;
              new_in_cart_offers.push(company)
            }
          })
          state.ordered_offers = new_in_cart_offers
        },
				SET_ORDERED_OFFERS(state, offers) {
					state.ordered_offers = offers
				},
				SET_SELECTED_IN_CART_OFFERS(state, uniqs) {
					state.selected_in_cart_offers = uniqs
				},
				SET_SELECTED_ORDERED_OFFERS(state, uniqs) {
					state.selected_ordered_offers = uniqs
				},
        REMOVE_FROM_IN_CART_OFFERS(state,uniq) {
          let new_in_cart_offers = [];
          state.in_cart_offers.forEach((company) => {
            let offers = company.offers.filter(offer => offer.uniq !== uniq);
            if (offers.length) {
              company.offers = offers;
              new_in_cart_offers.push(company)
            }
          })
          state.in_cart_offers = new_in_cart_offers
        },
				REMOVE_FROM_SELECTED_IN_CART_OFFERS(state, uniq) {
					state.selected_in_cart_offers = state.selected_in_cart_offers.filter(offer_uniq => offer_uniq !== uniq);
				},
        SET_MEASURE_REQUEST_CONTENT(state, data) {
          state.measure_request_content = data
        },
    },
    actions: {
        addOfferToCart({commit, dispatch}, offer) {
            commit('SET_ITEM_CART_SENT', offer.uniq);
            axios.post('/shopping_cart/add_to_cart', {offer: offer, offer_type: 'incart'}).then( res =>{
                commit('ADD_TO_CART', offer);
            }).catch(err => {
                console.error("OffersCartModule addOfferToCard", error);
                commit("SET_ERROR_MESSAGE", [
                    "Ошибка добавления в корзину",
                    "Извините, что-то пошло не так.",
                ]);
            }).finally(res => {
                commit('REMOVE_ITEM_CART_SENT', offer.uniq);
                dispatch('fetchBasketStatus');
            })

        },
        removeOfferFromCart({state, commit, dispatch}, {offer_type, offer}) {
            commit('SET_ITEM_CART_SENT', offer.uniq);
            axios.post('/shopping_cart/remove_from_cart', {offer_type, offer}).then( res =>{
                if (res.data.status === 'success') {
                  if (offer_type == 'ordered') {
                    if (Array.isArray(offer)) {
                      offer.forEach((offer_one) => {
                        commit('REMOVE_FROM_ORDERED_OFFERS', offer_one.uniq)
                        commit('REMOVE_FROM_SELECTED_IN_CART_OFFERS', offer_one.uniq)
                      })
                    } else {
                      commit('REMOVE_FROM_ORDERED_OFFERS', offer.uniq)
                      commit('REMOVE_FROM_SELECTED_IN_CART_OFFERS', offer.uniq)
                    }
                  } else {
                    commit('REMOVE_FROM_IN_CART_OFFERS', offer.uniq)
                    commit('REMOVE_FROM_SELECTED_IN_CART_OFFERS', offer.uniq)
                    commit('REMOVE_FROM_CART', offer);
                  }
                }
            }).catch(err => {
                console.error("OffersCartModule removeOfferFromCart", error);
                commit("SET_ERROR_MESSAGE", [
                    "Не удалось убрать заявку",
                    "Извините, что-то пошло не так.",
                ]);
            }).finally(res => {
                commit('REMOVE_ITEM_CART_SENT', offer.uniq);
                dispatch('fetchBasketStatus');
            })
        },
        fetchBasketStatus({commit}) {
            axios.get('/shopping_cart/state.json').then(res => {
                commit('FETCH_BASKET_STATUS', res.data)
            }).catch(err => {
                console.error("OffersCartModule fetchBasketStatus", error)
            })
        },
        getOffersInformation({state, commit, dispatch}, {guid, session_id}) {
            axios.post('/shopping_cart/measure_request_information.json', {measure_request: {guid, session_id}}).then( res => {
                if (res.data.status === 'success') {
                    commit('SET_MEASURE_REQUEST_CONTENT', res.data.content)
                }
            }).catch(err => {
                commit("SET_ERROR_MESSAGE", [
                    "Не удалось получить данные по заявке",
                    "Извините, что-то пошло не так.",
                ]);
            })
        }
    },
  }
export default OffersCartModule;

import { antiBurglaryCode, sunprotectionCode, viewprotectionCode, lowEmissionCode, doubleLowEmissionCode } from "../../../init_data/glass_types_codes.js";

const ProfileModule = {
  state: () => ({
		profile_params: undefined,
		next_profile_params: undefined
  }),
  mutations: {
    SET_PROFILE_PARAMS(state, param) {
      state.profile_params = new ProfileParam(param)
    },
    SET_NEXT_PROFILE_PARAMS(state, param) {
      state.next_profile_params = param
    },
    DEL_NEXT_PROFILE_PARAMS(state) {
      state.next_profile_params = undefined
    },
  },
  actions: {
    setNextParamsByWidthRanges({state,commit,dispatch,getters}, current_index) {
			const type_param = 'profile_width_ranges';
      dispatch('setNextParams', { type_param, current_index });
		},
    setNextParamsByProfileCameraRanges({state,commit,dispatch,getters}, current_index) {
			const type_param = 'profile_camera_ranges';
      dispatch('setNextParams', { type_param, current_index });
		},
    setNextParamsByCameraTypes({state,commit,dispatch,getters}, current_index) {
			const type_param = 'camera_types';
      dispatch('setNextParams', { type_param, current_index});
		},
    setNextParamsByGlassTypes({state,commit,dispatch,getters}, current_index) {
			const type_param = 'glass_types';
      dispatch('setNextParams', { type_param, current_index});
		},
    setNextParamsByHeatSavingLevel({state,commit,dispatch,getters}, current_index) {
			const type_param = 'heat_saving_levels';
      dispatch('setNextParams', { type_param, current_index});
		},

    setNextParams({state,commit,dispatch,getters}, {type_param, current_index}) {
			try {
				const new_params = new ProfileParam(state.profile_params.params);
				const type_param_value = new_params[type_param];
				if (type_param_value.includes(current_index)) {
					new_params[type_param] = type_param_value.filter(index => index!= current_index)
				} else {
					new_params[type_param] = [current_index, ...type_param_value]
				}
				commit("SET_NEXT_PROFILE_PARAMS", new_params);
			} catch {
			}
		},
    delNextParams({commit}) {
      commit("SET_NEXT_PROFILE_PARAMS", undefined);
		},
  },
  getters: {
		diff_profile_width_ranges: state => {
			if (!state.next_profile_params) return []
			return ArrayDiff(state.next_profile_params.profile_width_ranges, state.profile_params.profile_width_ranges)
		},
		diff_profile_camera_ranges: state => {
			if (!state.next_profile_params) return []
			return ArrayDiff(state.next_profile_params.profile_camera_ranges, state.profile_params.profile_camera_ranges)
		},
		diff_camera_types: state => {
			if (!state.next_profile_params) return []
			return ArrayDiff(state.next_profile_params.camera_types, state.profile_params.camera_types)
		},
		diff_glass_types: state => {
			if (!state.next_profile_params) return []
			return ArrayDiff(state.next_profile_params.glass_types, state.profile_params.glass_types)
		},
		diff_heat_saving_levels: state => {
			if (!state.next_profile_params) return []
			return ArrayDiff(state.next_profile_params.heat_saving_levels, state.profile_params.heat_saving_levels)
		},
  }
}

const profileCameraRanges = {
  1: [3, 3],
  2: [4, 4],
  3: [5, 5],
  4: [6, 8]
}

const checkIntersect = (r1, r2) => {
  const [r_min_start, r_max_start]= (r1[0] <= r2[0] ? [r1,r2] : [r2,r1])
  return r_min_start[1] >= r_max_start[0]
}

const ArrayDiff = (ar1, ar2) => {
	return [...ar1.filter(el => !ar2.includes(el)), ...ar2.filter(el => !ar1.includes(el))]
}

const CameraTypesHeatSaving = {
  14: {
    level: 'low',
    add_level: 'medium',
    glass_codes_for_add: [[lowEmissionCode],[sunprotectionCode]],
  },
  11: {
    level: 'medium',
    add_level: 'high',
    glass_codes_for_add: [[doubleLowEmissionCode], [lowEmissionCode, sunprotectionCode]],
  },
  12: {
    level: 'high',
  }
}

const CameraTypesGlassNotValid = {
  14: [[doubleLowEmissionCode], [lowEmissionCode, sunprotectionCode], [doubleLowEmissionCode, sunprotectionCode]],
  11: [[doubleLowEmissionCode, sunprotectionCode]],
  12: []
}

const widthRanges = {
	1: {
		width_range:  [1, 62],
		camera_types: [14,11],
		camera_range: [3, 4]
	},
	2: {
		width_range:  [70,75],
		camera_types: [14,11,12],
		camera_range: [3, 5]
	},
	3: {
		width_range:  [76,127],
		camera_types: [14,11,12],
		camera_range: [6, 8]
	},
};

class ProfileParam {
  constructor({profile_width, profile_camera_range, camera_types, glass_types}) {
    this._profile_width = profile_width;
    this._profile_camera_range = profile_camera_range;
    this._camera_types = camera_types;
    this._glass_types  = glass_types;
  }

  get params() {
		return {
			profile_width: 	this._profile_width,
			profile_camera_range: this._profile_camera_range,
			camera_types: 	this._camera_types,
			glass_types: 		this._glass_types
		}
  }

	/**
	 * -----------------------------------
 	 GETTERS SETTERS FOR CHECKBOXES
	 *
	**/
  get profile_width_ranges() {
    return this.widthRangeIndexes.filter((range_index) => {
      const range = widthRanges[range_index].width_range;
      return this._profile_width[0] <= range[0] && range[1] <= this._profile_width[1]
    });
  }
  set profile_width_ranges(ranges) {
		const added = ranges.length > this.profile_width_ranges.length;
		const err_mes = this.validateRanges(ranges, added);
		if (err_mes) throw new ValidationError(err_mes, ArrayDiff(ranges, this.profile_width_ranges))

		this._profile_width = this.getWidthByRanges(ranges);
		this.validateProfileCameraRangeByWidthRanges(added);
		this.validateCameraTypesByWidthRanges(added);
  }

  get profile_camera_ranges() {
    return this.cameraRangeIndexes.filter((range_index) => {
      const range = profileCameraRanges[range_index];
      return this._profile_camera_range[0] <= range[0] && range[1] <= this._profile_camera_range[1]
    });
  }
  set profile_camera_ranges(ranges) {
		const added = ranges.length > this.profile_camera_ranges.length;
		const err_mes = this.validateRanges(ranges, added);
		if (err_mes) throw new ValidationError(err_mes, ArrayDiff(ranges, this.profile_camera_ranges))

		this._profile_camera_range = this.getProfileCameraByRanges(ranges);
		this.validateWidthByProfileCameraRange(added);
  }

  get camera_types() {
    return this._camera_types
  }
  set camera_types(types) {
		const old_types = this.camera_types;
		const added = types.length > old_types.length;
		const err_mes = this.validateCameraTypes(types, added);
		if (err_mes) throw new ValidationError(err_mes, ArrayDiff(types, old_types))

    if (types.length === 2 && !types.includes(11)) types.push(11)
		this._camera_types = types;
		this.validateWidthByCameraTypes(added);
		if (added) {
			const added_types = types.filter(type => !old_types.includes(type));
			this.validateGlassTypesByCameraTypes(added_types)
		}
  }

  get glass_types() {
    return this._glass_types
  }

  set glass_types(types) {
		const err_mes = this.validateGlassTypes(types, this.glass_types);
		if (err_mes) throw new ValidationError(err_mes, ArrayDiff(types, this.glass_types))

		this._glass_types = types;
		this.validateCameraTypesByGlassTypes()
  }

	get heat_saving_levels() {
		return [...new Set(
			this.camera_types.map((type) => {
				const heat_saving = CameraTypesHeatSaving[type];
				if (heat_saving.add_level) {
					for (const codes of heat_saving.glass_codes_for_add) {
						const every = codes.every(code => this.glass_types.includes(code));
						if (every) return heat_saving.add_level
					}
				}
				return heat_saving.level
			})
		)]
	}
	set heat_saving_levels(levels) {
		const old_leves = this.heat_saving_levels;
		if (levels.length === 0)
			throw new ValidationError('Фильтр не может быть пустым', old_leves)
		const old_camera_types = [...this.camera_types];
		const added = old_leves.length < levels.length;
		if (added) {
			const added_levels = levels.filter(level => !old_leves.includes(level));
			added_levels.forEach((heat_saving_level) => {
				const type_with_level = this.findCameraTypeByHeatSavingLevel(heat_saving_level);
				const glass_codes_for_add = CameraTypesHeatSaving[type_with_level].glass_codes_for_add;
				if (glass_codes_for_add) {
					for (const codes of glass_codes_for_add) {
						const every = codes.every(code => this.glass_types.includes(code));
						if (every) this._glass_types = this.glass_types.filter(code => !codes.includes(code));
					}
				}
				if (!old_camera_types.includes(type_with_level)) {
					this.camera_types = [type_with_level, ...old_camera_types]
				}
			})
		} else {
			const deleted_levels = old_leves.filter(level => !levels.includes(level));
			deleted_levels.forEach((heat_saving_level) => {
				const level_index = this.getHeatSavingLevelIndex(heat_saving_level);
				const new_leves_indexes = levels.map(level => this.getHeatSavingLevelIndex(level))
				const type_with_level = this.findCameraTypeByHeatSavingLevel(heat_saving_level);
				if (new_leves_indexes.every(index => index > level_index)) {        // UP heat saving
					const new_types = old_camera_types.filter(camera_type => {
						const camera_type_heat_level = CameraTypesHeatSaving[camera_type].level;
						const camera_type_heat_index = this.getHeatSavingLevelIndex(camera_type_heat_level);
						return camera_type_heat_index > level_index
					});
					this.camera_types = new_types
				} else if (new_leves_indexes.every(index => index < level_index)) { // DOWN heat saving
					if (old_camera_types.includes(type_with_level)) {
						this.camera_types = old_camera_types.filter(type => type != type_with_level)
					}
					const type_with_add_level = this.findCameraAddTypeByHeatSavingLevel(heat_saving_level);
					if (old_camera_types.includes(type_with_add_level)) {
						const glass_codes_for_add = CameraTypesHeatSaving[type_with_add_level].glass_codes_for_add;
						for (const codes of glass_codes_for_add) {
							const every = codes.every(code => this.glass_types.includes(code));
							if (every) this._glass_types = this.glass_types.filter(code => !codes.includes(code));
						}
					}
				} else {                                                             //disable midium
					throw new ValidationError('Невозможно отключить среднее значение, при включенных крайних', ArrayDiff(levels, old_leves))
				}
			})
		}
	}
	//---------------------------------------------------------------------------------------
	/**
	 * -----------------------------------
 	 VALIDATION
	 *
	**/
	validateCameraTypes(types, added) {
  	if (types.length === 0) return 'Фильтр не может быть пустым'
		if (!added) {
			if (types.length === 2 && !types.includes(11)) return 'Невозможно отключить среднее значение, при включенных крайних'
		}
	}
	validateRanges(ranges, added) {
  	if (ranges.length === 0) return 'Фильтр не может быть пустым'
		if (!added) {
			if (this.checkRangesSpacing(ranges)) return 'Невозможно отключить среднее значение, при включенных крайних'
		}
	}
	validateGlassTypes(types) {
		if (types.includes(lowEmissionCode) && types.includes(doubleLowEmissionCode)) {
			return "Фильтр Защита и безопасность: Невозможно выбрать одновременно Двойное низкоэмиссионное стекло и Низкоэмиссионное стекло"
		}
		if (types.includes(sunprotectionCode) && types.includes(viewprotectionCode)) {
			return "Фильтр Защита и безопасность: Невозможно выбрать одновременно Мультистекло и Тонированное или зеркальное стекло от посторонних взглядов";
		}
		let selected_one = this.camera_types.length === 1 && this.camera_types.includes(14); // выбрана только одна камера
		if (selected_one) {
			let without_anti_bulgary = types.filter(type_code => type_code !== antiBurglaryCode);
			if (without_anti_bulgary.length > 2) {
				return "Фильтр Защита и безопасность: Невозможно выбрать больше 2-х типов стекла при 1 камере в СП"
			}
			if (types.includes(doubleLowEmissionCode)) {
				return "Фильтр Защита и безопасность: Невозможно выбрать Низкоэмиссионное двойное стекло при 1 камере в СП"
			}
			if (types.includes(lowEmissionCode) && types.includes(sunprotectionCode)) {
				return "Фильтр Защита и безопасность: Невозможно выбрать одновременно Мультистекло и Низкоэмиссионное стекло при 1 камере в СП"
			}
		}
		if (!this.camera_types.includes(12)) {
			if (types.includes(doubleLowEmissionCode) && types.includes(sunprotectionCode)) {
				return "Фильтр Защита и безопасность: Невозможно выбрать одновременно Двойное низкоэмиссионное стекло и Мультистекло при 2-х камерах в СП"
			}
		}
	}
	//---------------------------------------------------------------------------------------
	/**
	 * -----------------------------------
 	 RECALCULATION
	 *
	**/
	validateCameraTypesByGlassTypes() {
		let valid_camera_types = this.camera_types.filter(type => {
			let valid = true;
			const not_valid_glass_sets_codes = CameraTypesGlassNotValid[type];
			not_valid_glass_sets_codes.forEach(codes => {
				const every = codes.every(code => this.glass_types.includes(code));
				if (every) valid = false
			})
			return valid
		})

		const without_anti_bulgary = this.glass_types.filter(type_code => type_code !== antiBurglaryCode);
		if (without_anti_bulgary.length > 2) {
			valid_camera_types = valid_camera_types.filter(type => type != 14)
		}
		if (valid_camera_types.length < this.camera_types.length) this.camera_types = valid_camera_types
	}
	validateGlassTypesByCameraTypes(added_types) {
		let new_glass_types = [...this.glass_types];
		added_types.forEach((type) => {
			const not_valid_glass_sets_codes = CameraTypesGlassNotValid[type];
			not_valid_glass_sets_codes.forEach(codes => {
				const every = codes.every(code => new_glass_types.includes(code));
				if (every) new_glass_types = new_glass_types.filter(type => !codes.includes(type))
			})
		})
		if (added_types.includes(14)) {
			const without_anti_bulgary = new_glass_types.filter(type_code => type_code !== antiBurglaryCode);
			if (without_anti_bulgary.length > 2) {
				new_glass_types = [...new_glass_types.slice(0,2), antiBurglaryCode]
			}
		}
		if (new_glass_types.length != this.glass_types.length) this._glass_types = new_glass_types
	}
	validateWidthByCameraTypes(added) {
		const old_width_ranges = this.profile_width_ranges;
		if (added) {
			this.camera_types.forEach(type => {
				const some_include = old_width_ranges.some(range_index => widthRanges[range_index].camera_types.includes(type))
				if (!some_include) {
					const new_width_ranges = this.widthRangeIndexes.filter((range_index) => {
						if (old_width_ranges.includes(range_index)) return true
						const types = widthRanges[range_index].camera_types;
						if (types.includes(type)) return true
					});
					if (new_width_ranges.length != old_width_ranges.length) this.profile_width_ranges = new_width_ranges
				}
			})
		} else {
			const valid_ranges = old_width_ranges.filter((range_index) => {
				const types = widthRanges[range_index].camera_types;
				return types.some(type => this.camera_types.includes(type))
			})
			if (valid_ranges.length < old_width_ranges.length) this.profile_width_ranges = valid_ranges
		}
	}
	validateWidthByProfileCameraRange(added) {
		const old_width_ranges = this.profile_width_ranges;
		let new_width_ranges = [...old_width_ranges];

		if (added) {
			for (const camera_range_index of this.profile_camera_ranges) {
				const camera_range = profileCameraRanges[camera_range_index];

				const some_width_range_intersect = old_width_ranges.some(width_range_index => {
					const camera_range_by_width = widthRanges[width_range_index].camera_range;
					return checkIntersect(camera_range, camera_range_by_width);
				})

				if (some_width_range_intersect) continue

				for (const width_range_index of this.widthRangeIndexes) {
					if (new_width_ranges.includes(width_range_index)) continue
					const camera_range_by_width = widthRanges[width_range_index].camera_range;
					if (checkIntersect(camera_range, camera_range_by_width)) new_width_ranges.push(width_range_index)
				}
			}
		} else {
			new_width_ranges = old_width_ranges.filter((width_range_index) => {
				const camera_range_by_width = widthRanges[width_range_index].camera_range;
				return this.profile_camera_ranges.some(camera_range_index => {
					const camera_range = profileCameraRanges[camera_range_index];
					return checkIntersect(camera_range_by_width, camera_range);
				})
			})
		}
		if (old_width_ranges.length != new_width_ranges.length) this.profile_width_ranges = new_width_ranges
	}
	validateCameraTypesByWidthRanges(added) {
		let new_types = [...this.camera_types];
		if (added) {
			this.profile_width_ranges.forEach((range_index) => {
				const types = widthRanges[range_index].camera_types;
				const all_uniqs = types.every(type => !new_types.includes(type))
				if (all_uniqs) new_types = [...new_types, ...types]
			})
		} else {
			const valid_types = this.getTypesByWidthRanges(this.profile_width_ranges);
			new_types = valid_types.filter(type => new_types.includes(type));
		}
		if (this.camera_types.length != new_types.length) this.camera_types = new_types
	}
	validateProfileCameraRangeByWidthRanges(added) {
		if (added) {
			this.profile_width_ranges.forEach((range_index) => {
				const range = widthRanges[range_index].camera_range;
				const intersect = checkIntersect(this._profile_camera_range, range);
				if (!intersect) {
					const new_camera_range = [...this._profile_camera_range];
					if (range[0] < this._profile_camera_range[0]) new_camera_range[0] = range[0]
					if (range[1] > this._profile_camera_range[1]) new_camera_range[1] = range[1]
					this._profile_camera_range = new_camera_range
				}
			})
		} else {
			const valid_range = this.getCameraRangeByWidthRanges(this.profile_width_ranges);
			const new_camera_range = [...this._profile_camera_range];
			if (this._profile_camera_range[0] < valid_range[0]) new_camera_range[0] = valid_range[0]
			if (this._profile_camera_range[1] > valid_range[1]) new_camera_range[1] = valid_range[1]
			this._profile_camera_range = new_camera_range
		}
	}
	//---------------------------------------------------------------------------------------
	/**
	 * -----------------------------------
 	 HELPER METHODS
	 *
	**/
	findCameraTypeByHeatSavingLevel(heat_saving_level) {
		return Object.keys(CameraTypesHeatSaving).map(type => Number(type)).find((type) => {
			return CameraTypesHeatSaving[type].level === heat_saving_level
		});
	}
	findCameraAddTypeByHeatSavingLevel(heat_saving_level) {
		return Object.keys(CameraTypesHeatSaving).map(type => Number(type)).find((type) => {
			return CameraTypesHeatSaving[type].add_level === heat_saving_level
		});
	}
	getHeatSavingLevelIndex(heat_saving_level) {
		return ['low','medium','high'].indexOf(heat_saving_level)
	}
	checkRangesSpacing(range) {
		const sorted_ranges = range.sort();
		return sorted_ranges[sorted_ranges.length - 1] - sorted_ranges[0] !==  sorted_ranges.length - 1
	}
	getWidthByRanges(value) {
		const sorted_ranges = value.sort();
		const min = widthRanges[sorted_ranges[0]].width_range;
		const max = widthRanges[sorted_ranges[sorted_ranges.length - 1]].width_range;
		return [min[0], max[1]]
	}
	getProfileCameraByRanges(value) {
		const sorted_ranges = value.sort();
		let min = profileCameraRanges[sorted_ranges[0]];
		let max = profileCameraRanges[sorted_ranges[sorted_ranges.length - 1]];
		return [min[0], max[1]]
	}
	getTypesByWidthRanges(ranges) {
		return [...new Set(
			ranges.map((range_index) => widthRanges[range_index].camera_types).flat()
		)]
	}
	getCameraRangeByWidthRanges(value) {
		const sorted_ranges = value.sort();
		const min = widthRanges[sorted_ranges[0]].camera_range;
		const max = widthRanges[sorted_ranges[sorted_ranges.length - 1]].camera_range;
		return [min[0], max[1]]
	}
  get widthRangeIndexes() {
    return Object.keys(widthRanges).map(range_index => Number(range_index))
  }
  get cameraRangeIndexes() {
    return Object.keys(profileCameraRanges).map(range_index => Number(range_index))
  }
}

class ValidationError extends Error {
  constructor(message, not_valid, ...params) {
		super(...params);
    this.name = 'ValidationError';
    this.message = message;
    this.not_valid = not_valid;
  }
}

export default ProfileModule;

import { values_for_slider_types, casements_for_window_types, casement_for_door_types } from "../../../init_data/window_type_slider.js";

const AllCasementTypes = {
	dead_window: { name: 'Глухая', short_name: 'Г' },
	rotary: { name: 'Поворотная', short_name: 'П' },
	swing_out: { name: 'Поворотно-откидная', short_name: 'ПО' }
};

const CurrentWindowModule = {
  state: () => ({
		all_casement_types: AllCasementTypes,
    window_casements: undefined,
    door_casement: undefined,
    quantity: 1,
    window_color_inside: { name: 'нет', rgb: '#00000000' },
    window_color_outside: { name: 'нет', rgb: '#00000000' },
    window_color_solid: { name: 'Белая', rgb: '#FFFFFFFF' },
    // количество не глухих створок текущего окна (для детского замка)
    window_full_name: '',
		// ***** MosquitoFilter *****
    window_parts_generated: [],
		as_mosquitoscreen: [],
		as_petscreen: [],
		as_dustscreen: [],
		// --------------------------
  }),
  mutations: {
    SET_CASEMENTS(state, casements) {
      state.casements = casements
    },
    SET_WINDOW_CASEMENTS(state, casements) {
      state.window_casements = casements
    },
    SET_DOOR_CASEMENT(state, casement) {
      state.door_casement = casement
    },
    SET_DOOR(state, door) {
      if (door) {
        state.door = door;
        state.door_side = door.side;
        let door_image_code = door.side + '/';
        if (door.glass_type  === 'half_sandwich') door_image_code += 'half_sandwich/'
        if (door.type === 'swing_out') door_image_code += '2.webp'
        if (door.type === 'rotary') door_image_code    += '1.webp'
        state.door_image_code = door_image_code
      } else {
        state.door = null;
        state.door_side = null;
      }
    },
    SET_PARTS_FOR_GRID(state, [window_parts_generated,avalibl_parts_for_grid]) {
      state.window_parts_generated = window_parts_generated;
      state.avalibl_parts_for_grid = avalibl_parts_for_grid;
    },
    SET_GRID(state,[type, selected]) {
			state[type] = selected;
    },
    SET_WINDOW_FULL_NAME(state, name) {
       state.window_full_name = name
    },
    SET_CASEMENT_TYPE(state, casement_type) {
      state.casement_type = parseInt(casement_type);
    },
    SET_CASEMENTS_COUNT(state, count) {
      state.casements_count = count
    },
    SET_WITH_FRAMUGA(state, with_framuga) {
      state.with_framuga = with_framuga
    },
    SET_QUANTITY(state, quantity) {
      state.quantity = quantity;
    },
    SET_WINDOW_COLOR_INSIDE(state, window_color_inside) {
      state.window_color_inside = window_color_inside;
    },
    SET_WINDOW_COLOR_OUTSIDE(state, window_color_outside) {
      state.window_color_outside = window_color_outside;
    },
    SET_WINDOW_COLOR_SOLID(state, window_color_solid) {
      state.window_color_solid = window_color_solid;
    },
  },
  actions: {
		setCasements({state,commit,dispatch}, casements) {
			commit('SET_CASEMENTS', casements);
      if (!casements) return
      let all_count = Object.values(casements).length; // casements + framuga
      let casements_count = Object.values(casements).filter(cas => cas.casement).length;
			commit('SET_CASEMENTS_COUNT', casements_count);
			commit('SET_WITH_FRAMUGA', (all_count !== casements_count));
			// set window_parts and avalibl_parts for grid
			//FOR MOSQ GRID
			let window_parts_generated = [];
			let avalibl_parts_for_grid = [];

			let wd_part_names = state.all_window_types[state.window_type];
			wd_part_names.forEach((name,index) => {
				let casement = casements[index];
				let width = casement['width'];
				let height = casement['height'];
				let size = `${width}*${height}`;
				let type = state.all_casement_types[casement['type']];
				window_parts_generated.push({ name, size, type, width, height})

				if (name !== 'Фрамуга' && casement['type'] !== 'dead_window') {
					avalibl_parts_for_grid.push(name)
				}
			})
			commit('SET_PARTS_FOR_GRID', [window_parts_generated, avalibl_parts_for_grid]);
      // если скроем фильтр, то обнулим выбранную до этого фурнитуру
      if (avalibl_parts_for_grid.length === 0) {
        commit('SET_SELECTED_FURNITURES', [])
      }
			// FIlters for delete invalid grid
			dispatch('setGrid',['as_mosquitoscreen',state.selectedMosq.filter((name) => avalibl_parts_for_grid.includes(name))]);
			dispatch('setGrid',['as_petscreen',state.selectedPet.filter((name) => avalibl_parts_for_grid.includes(name))]);
			dispatch('setGrid',['as_dustscreen',state.selectedDust.filter((name) => avalibl_parts_for_grid.includes(name))]);
			//---------------
		},
		setGrid({ state,commit,dispatch },[type,selected]) {
			commit('SET_GRID',[type, selected]);
      let add;
			if (selected.length === 0) add = false
      else add = true
			commit('TOGGLE_ADDITIONAL_SERVICES',[type, add])
		},
    setDfDoorType({state, commit}, type) {
      let df_value = casement_for_door_types[type];
			commit('SET_DOOR', df_value.door);
    },
    deleteDoorCasement({commit}) {
			commit('SET_DOOR', null);
    },
    setSliderTypesDfCasements({state, commit, dispatch, rootState}, type) {
			let slider_type_value = values_for_slider_types[type];
      let window_type = slider_type_value.window_type;
      if (window_type) {
        let casements = casements_for_window_types[window_type];
        commit('SET_WINDOW_CASEMENTS', casements)
      } else {
        commit('SET_WINDOW_CASEMENTS', null)
      }
      let door_type = slider_type_value.door_type;
      if (door_type) {
        let casement = casement_for_door_types[door_type];
        commit('SET_DOOR_CASEMENT', casement)
      } else {
        commit('SET_DOOR_CASEMENT', null)
      }
			//
			// IF THE MOBILE AGGREGATOR PAGE WAS LOADED THEN DO NOT getOFFERS
			//
			if (!(rootState.aggregator_page_load && rootState.isMobile))  { 
				dispatch('getOffers')
      }
    },
    setInitialWindowColor({ commit }, [
      color_inside,
      color_outside,
      color_solid
    ]) {
      commit("SET_WINDOW_COLOR_INSIDE", JSON.parse(color_inside));
      commit("SET_WINDOW_COLOR_OUTSIDE", JSON.parse(color_outside));
      commit("SET_WINDOW_COLOR_SOLID", JSON.parse(color_solid));
    }
  },
  getters: {
		avalibl_parts_for_grid: state => {
      let avalibl = [];
      if (state.window_casements) {
        state.window_casements.forEach(casement => {
          if (casement.type !== 'dead_window') avalibl.push(casement.name)
        })
      }
      if (state.door_casement) {
        if (state.door_casement.type !== 'dead_window') avalibl.push(state.door_casement.name)
      }
      return avalibl
		},
  }
}


export default CurrentWindowModule;

const SortFilterModule = {
  state: () => ({
    active_sorting: "price",
    sort_rating: "desc",
    sort_price: "asc",
    // флаг для показа фильтров слева на мобилке
    show_left_filters: false,
  }),
  mutations: {
    SORT_DEFAULT_CHANGED(state) {
      state.sort_default_changed = true
    },
    SET_ACTIVE_SORTING(state, active_sorting) {
      state.active_sorting = active_sorting
    },
    SET_SORT_RATING(state, sort_rating) {
      state.sort_rating = sort_rating
    },
    SET_SORT_PRICE(state, sort_price) {
      state.sort_price = sort_price
    },
    SET_SHOW_LEFT_FILTERS(state, value) {
      state.show_left_filters = value
    }
  },
  actions: {
    setActiveSorting({ commit, dispatch }, { active_sorting, upd_offers }) {
      commit('SET_ACTIVE_SORTING', active_sorting);
      commit('WITHOUT_TOPS');
      if (upd_offers) {
        dispatch('getOffers')
      }
    },
    setSortRating({ commit, dispatch }, sort_rating) {
      commit('SET_SORT_RATING', sort_rating);
      commit('WITHOUT_TOPS');
      dispatch('getOffers')
    },
    setSortPrice({ commit, dispatch }, sort_price) {
      commit('SET_SORT_PRICE', sort_price);
      commit('WITHOUT_TOPS');
      dispatch('getOffers')
    },
  }
}


export default SortFilterModule;

const PageModule = {
    state: () => ({
      page: 1,
      per_page: 20,
      page_with_offers: false
    }),
    mutations: {
      SET_PAGE(state, page) {
        state.page = page;
      },
      SET_PAGE_WITH_OFFERS(state, page_with_offers) {
        state.page_with_offers = page_with_offers
      }
    },
  }
  
  export default PageModule;
import axios from 'axios'
const CompanyModel = {
  state: () => ({
    selected_companies: [],
    all_companies: [],
    companies_in_selected_cities: [],
  }),
  mutations: {
    SET_ALL_COMPANY_LIST(state, all_companies) {
      state.all_companies = all_companies
    },
    SET_COMPANIES_IN_SELECTED_CITIES(state, companies) {
      state.companies_in_selected_cities = companies
    },
    SET_SELECTED_COMPANIES(state, value) {
      state.selected_companies = value
    },
  },
  actions: {
    setSelectedCompanies({ dispatch, commit }, value) {
      commit('SET_SELECTED_COMPANIES', value)
      dispatch('getTimeoutFiltersOffers')
    },
    setCompaniesByCities({ commit, state }, cities) {
      let cities_ids = cities.map(item => item.id);
      axios.post('/api/calculator/get_window_companies_list.json',{cities_ids}).then(response => {
        commit('SET_COMPANIES_IN_SELECTED_CITIES', response.data.companies)
      }).catch(function (error) {
        let parsed = JSON.parse(error.request.response)
        console.error("get_window_companies_list error", error, parsed);
      })
    },
    removeCompanyFromSelectedList({commit, state, dispatch}, rItem) {
      let companies = state.selected_companies.filter(item => item != rItem.id);
      commit('SET_SELECTED_COMPANIES', companies);
      dispatch('getTimeoutFiltersOffers');
    }
  },
}


export default CompanyModel;
import axios from 'axios'

import { railsData } from "../../../init_data/rails_data.js";

const CitiesModule = {
  state: () => ({
    cities: [],
    all_cities: [],
    top_cities: [],
    selected_cities: [],
		user_geo: undefined,
		user_city_cookies_empty: undefined,
		// DIALOGS
    city_confirm_dialog: false,
    city_select_dialog: false,
		// and user_geo in cookies
		// or user select city
    user_confirm_city: undefined,
    show_empty_cities_dialog: false
  }),
  mutations: {
    OPEN_CONFIRM_CITY_DIALOG(state) {
      state.city_confirm_dialog = true
    },
    CLOZE_CONFIRM_CITY_DIALOG(state) {
      state.city_confirm_dialog = false
    },
    OPEN_SELECT_CITY_DIALOG(state) {
      state.city_select_dialog = true
    },
    CLOZE_SELECT_CITY_DIALOG(state) {
      state.city_select_dialog = false
    },
    SET_INITIAL_CITIES_DATA(state, data) {
      state.cities = data.cities;
      state.top_cities = data.topCities;
    },
    SET_SELECTED_CITIES(state, selected) {
      state.selected_cities = selected
    },
    SET_CONFIRM_CITY(state, c_city) {
      state.user_confirm_city = c_city;
    },
    SET_USER_GEO(state, geo) {
      state.user_geo = geo;
    },
    SET_SHOW_EMPTY_CITIES_DIALOG(state, show) {
      state.show_empty_cities_dialog = show
    }
  },
  actions: {
    setSelectedCities({ commit, dispatch }, cities) {
      commit('SET_SELECTED_CITIES', cities);
    },
		saveCitiesToCookie({state}) {
			$cookies.set('selected_cities_ko', JSON.stringify(state.selected_cities), "24h", "/", "." + main_domain);
		},
    getCitiesList({ commit }) {
      axios.get("/api/calculator/cities").then(res => {
        commit('SET_INITIAL_CITIES_DATA', res.data);
      }).catch(error => {
        console.error("CitiesModule getCitiesList error:", error);
      })
    },
		searchCity({ dispatch }) {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						let coords = {latitude: position.coords.latitude, longitude:  position.coords.longitude }
						dispatch("getCityByApi", {coords});
					},
					(error) => {
						console.error("GEOLOCATION ERROR", error.message);
						dispatch("getCityByApi",{ coords: { latitude: null, longitude:  null } });
					},
					{
						enableHighAccuracy: true,
						timeout: 5000
					})
			} else {
				dispatch("getCityByApi",{ coords: { latitude: null, longitude:  null } });
			}
		},
    getCityFromCookie({state, dispatch }) {
      if ($cookies.isKey('selected_cities_ko')) {
				state.user_city_cookies_empty = false;
      	let cookies_cities = $cookies.get('selected_cities_ko');
			  dispatch('setSelectedCities', cookies_cities);
      } else {
			  dispatch('setSelectedCities', []);
				state.user_city_cookies_empty = true;
			}
    },
    getCityByApi({rootState, commit, state, dispatch }, coords) {
      axios.post('/api/calculator/get_city.json', coords).then(res => {
        let userGeo = res.data;
				if (userGeo) {
          commit('SET_USER_GEO', [userGeo]);
					if (state.user_city_cookies_empty && !railsData.current_company) {
						// set city
          	commit('SET_CONFIRM_CITY', false);
          	dispatch('setSelectedCities', state.user_geo);
						dispatch('getOffers');
					} else {
						// or check user geo city == (cookies city)
						let selected_ids = state.selected_cities.map(item => item.id);
						let location_id = state.user_geo.id
						let user_geo_in_cookies = selected_ids.includes(location_id);
          	commit('SET_CONFIRM_CITY', user_geo_in_cookies);
					}
				} else {
          let cookies_cities = $cookies.isKey('selected_cities_ko') ? !!JSON.parse($cookies.get('selected_cities_ko')).length : null
					if (!cookies_cities && rootState.first_page_load && !railsData.current_company) {
            commit('SET_SHOW_EMPTY_CITIES_DIALOG', true);
          }
        }
      }).catch(error => {
        console.error("ERROR IN Api GET_CITY", error);
      })
    },
  }
}


export default CitiesModule;

import axios from 'axios';

import router from '../../../router/index';

const OrderFormModule = {
    state: () => ({
      activities_touched: false,
			repair_status: 'send',
			phone_confirm: false,
			agreed_rules: '',
      in_cart_dialog: false,
			selected_companies: [],
			activities: [],
			user_message: '',
			phone: '',
			email: '',
			name: '',
			address: '',
			photo_ids: [],
			video_ids: [],
			document_ids: [],
			phone_confirm_form: undefined,
			message_form: undefined,
			repair_order_complite: false,
			quick_order_complite: false,
    }),
    mutations: {
				SET_IN_CART_DIALOG(state, value) {
					state.in_cart_dialog = value
				},
				FORM_SET_photo_IDS(state, ids) {
					state.photo_ids = ids
				},
				FORM_SET_video_IDS(state, ids) {
					state.video_ids = ids
				},
				FORM_SET_document_IDS(state, ids) {
					state.document_ids = ids
				},
				FORM_SET_SELECTED_COMPANIES(state,companies) {
					state.selected_companies = companies
				},
				FORM_SET_ACTIVITIES(state,activities) {
					state.activities = activities
				},
				REPAIR_FORM_REFRESH(state) {
					state.repair_order_complite = false
				},
				REPAIR_ORDER_COMPLITE(state) {
					state.repair_order_complite = true
				},
				QUICK_FORM_REFRESH(state) {
					state.quick_order_complite = false
				},
				QUICK_ORDER_COMPLITE(state) {
					state.quick_order_complite = true
				},
				SET_PHONE_CONFIRM_FORM(state,form) {
					state.phone_confirm_form = form;
				},
				SET_MESSAGE_FORM(state,form) {
					state.message_form = form;
				},
				FORM_SET_MESSAGE(state,message) {
					state.user_message = message
				},
        FORM_SET_ACTIVITIES_TOUCHED(state, touched) {
          state.activities_touched = touched
        },
				FORM_SET_AGREE(state,value) {
					state.agreed_rules = value
				},
				FORM_USER_CONFIRM_PHONE(state) {
					state.phone_confirm = true
				},
				FORM_USER_UNCONFIRM_PHONE(state) {
					state.phone_confirm = false
				},
        FORM_SET_REPAIR_STATUS(state, status) {
          state.repair_status = status
        },
        FORM_SET_USER_NAME(state,name) {
					state.name = name
        },
        FORM_SET_USER_EMAIL(state,email) {
					state.email = email
        },
        FORM_SET_USER_PHONE(state,phone) {
					state.phone = phone
        },
        FORM_SET_USER_ADDRESS(state,address) {
					state.address = address
        },
    },
    actions: {
      formResetAllFields({commit}) {
        commit('FORM_SET_ACTIVITIES_TOUCHED',false);
        commit('FORM_SET_ACTIVITIES',[]);
        commit('FORM_SET_MESSAGE', '');
        commit('FORM_SET_photo_IDS',[]);
				commit('FORM_SET_video_IDS',[]);
				commit('FORM_SET_document_IDS',[]);
				commit('FORM_SET_USER_NAME','');
        commit('FORM_SET_USER_EMAIL','');
				commit('FORM_USER_UNCONFIRM_PHONE');
        commit('FORM_SET_USER_PHONE','');
      },
      submitToAdmins({commit,dispatch,getters,state},recaptcha_token) {
        let measure = {
          customer_name: state.name,
          customer_phone: state.phone,
          customer_email: state.email,
          comment: state.user_message,
        }
        let data = {
          measure,
          photo_ids: state.photo_ids,
          video_ids: state.video_ids,
          document_ids: state.document_ids,
          city_id_for_measure: getters.params.city_ids,
          'g-recaptcha-response': recaptcha_token
        }
        axios.post('/shopping_cart/submit_measure_to_admins', data).then(res=> {
          if (res.data.status == 'success') {
            commit('QUICK_ORDER_COMPLITE');
            dispatch('formResetAllFields');
          }
        }).catch(function (error) {
          let parsed = JSON.parse(error.request.response);
          console.error("api error", error, parsed);
        })
      },
			submitRepair({getters,state, dispatch, commit}) {
				if (state.phone_confirm_form.validate()) {
					let measure = {
						customer_name: state.name,
						customer_phone: state.phone,
						customer_email: state.email,
						comment: state.user_message
					}
					let data = {
						city_id: getters.city_for_statistic,
						activities: state.activities,
						companies: state.selected_companies,
						photo_ids: state.photo_ids,
						video_ids: state.video_ids,
						document_ids: state.document_ids,
            city_id_for_measure: getters.params.city_ids,
						measure,
					}
					axios.post('/shopping_cart/create_repair_window_request', data).then(res=> {
						if (res.data.status == 'success') {
							commit('REPAIR_ORDER_COMPLITE');
							commit('FORM_SET_SELECTED_COMPANIES',[]);
              dispatch('formResetAllFields');
						}
					}).catch(function (error) {
					 	let parsed = JSON.parse(error.request.response);
					 	console.error("api error", error, parsed);
					})
				}
			},
			submitPerformOrder({getters, rootState, dispatch, state, commit }) {
				if (state.phone_confirm_form.validate()) {
          let offers = rootState.offersCartM.in_cart_offers.map(company => company.offers).flat();
          let selected_offers = offers.filter(offer => rootState.offersCartM.selected_in_cart_offers.includes(offer.uniq));

					let measure = {
						customer_name: state.name,
						customer_phone: state.phone,
						customer_email: state.email,
						comment: state.user_message,
					}
					let data = {
						offers: selected_offers,
						photo_ids: state.photo_ids,
						video_ids: state.video_ids,
            city_id_for_measure: getters.params.city_ids,
						document_ids: state.document_ids,
						measure,
					}
					axios.post('/shopping_cart/perform_ordering', data).then(res=> {
						if (res.data.status == 'success') {
              dispatch('fetchBasketStatus');
							commit('SET_SELECTED_IN_CART_OFFERS',[]);
							commit('SET_IN_CART_OFFERS',[]);
							commit('ORDER_COMPLITE');
              dispatch('formResetAllFields');
						}
					}).catch(function (error) {
					 	let parsed = JSON.parse(error.request.response);
					 	console.error("api error", error, parsed);
					})
				}
      }
    },
}

export default OrderFormModule;
